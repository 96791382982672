import { FC, useEffect } from 'react';

import { Empty, Spin } from 'antd';
import classNames from 'classnames';
import { Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { useGetPropertiesMutation } from 'services/properties/propertyApiService';

import s from './Properties.module.scss';

const Properties: FC = () => {
  const [getProperties, { isLoading, data }] = useGetPropertiesMutation();

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 100,
    };
    getProperties(criteria);
  }, [getProperties]);

  const editableProperties = data?.properties?.filter((item) => item.isEditable) || [];

  return (
    <div className={s.wrapper}>
      <Typography>Your properties</Typography>
      {isLoading && (
        <div className={s.loadingWrapper}>
          <Spin size="large" style={{ color: '#8E53E8', fontSize: 64 }} />
        </div>
      )}
      {data && (
        <div
          className={classNames(s.components, {
            [s['more-than-4']]:
              data!.properties?.filter((item) => item.isEditable === true).length > 4,
          })}>
          {editableProperties.length > 0 ? (
            editableProperties.map((item, index) => (
              <PropertyCard key={index} property={item} editable={true} />
            ))
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 260 }}
              description={<Typography type="h3">No properties</Typography>}
              className={s.empty}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default Properties;
