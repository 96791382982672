import { FC } from 'react';

import { PhoneFilled } from '@ant-design/icons';
import { Typography } from 'ui';

import s from './OwnerCard.module.scss';

interface OwnerCardProps {
  userId?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  profileImageURL?: string;
  hideMobile?: boolean;
}

const OwnerCard: FC<OwnerCardProps> = ({
  firstName,
  lastName,
  mobileNumber,
  profileImageURL,
  hideMobile,
}) => {
  return (
    <div className={s.wrapper}>
      <div className={s.imageWrapper} style={hideMobile ? { paddingBottom: 0 } : {}}>
        <img
          src={
            profileImageURL !==
            'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/'
              ? profileImageURL
              : '/assets/images/avatar/avatar.svg'
          }
          width={50}
          height={50}
          style={{ borderRadius: '50%' }}
          alt="agent"
        />
        <div>
          <Typography type="h4">
            {firstName} {lastName}
          </Typography>
          <Typography type="label">Owner</Typography>
        </div>
      </div>
      {!hideMobile && (
        <div className={s.infoWrapper}>
          <div className={s.agenciesImage}>
            <PhoneFilled style={{ fontSize: 20, color: '#CED2E5' }} />

            <Typography type="h6" weight={700}>
              {mobileNumber}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
export default OwnerCard;
