import React, { FC, useCallback } from 'react';

import { FacebookFilled, InstagramFilled } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { Icon } from 'ui';

import s from './footer.module.scss';

const Footer: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname.includes('dashboard');
  const goToDashboard = useCallback(() => {
    navigate(DASHBOARD_ROUTE);
  }, [navigate]);

  return (
    <>
      {!isDashboard && (
        <div className={s.footerWrapper}>
          <div className={s.footerButtons}>
            <Button type="link" onClick={goToDashboard}>
              <Icon width={200} name="logo" />
            </Button>
            <div className={s.menu}>
              <Button type="link" className={s.menuItem}>
                Buy
              </Button>
              <Button type="link" className={s.menuItem}>
                Rent
              </Button>
              <Button type="link" className={s.menuItem}>
                About
              </Button>
              <Button type="link" className={s.menuItem}>
                FAQ
              </Button>
              <Button type="link" className={s.menuItem}>
                Pricing
              </Button>
            </div>
            <div className={s.social}>
              <a href="https://www.w3schools.com/" target="_blank">
                <FacebookFilled style={{ fontSize: 20, color: '#7F86A4' }} />
              </a>
              <a href="https://www.w3schools.com/" target="_blank">
                <InstagramFilled style={{ fontSize: 20, color: '#7F86A4' }} />
              </a>
            </div>
          </div>
          <p className={s.footerCompanyText}>
            © {dayjs().year()} onthatstreet.com All rights reserved.
          </p>
        </div>
      )}
    </>
  );
};
export default Footer;
