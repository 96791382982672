import { FC } from 'react';

import { useSearchParams } from 'react-router-dom';

import LandingImage from '../landing-image/LandingImage';
import s from './Register.module.scss';
import RegisterForm from './register-form';

const Register: FC = () => {
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get('inviteId');

  return (
    <div className={s.wrapper}>
      <LandingImage />
      <RegisterForm inviteId={inviteId!} />
    </div>
  );
};

export default Register;
