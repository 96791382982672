import { FC, useEffect } from 'react';

import {
  FacebookFilled,
  GlobalOutlined,
  MailOutlined,
  PhoneFilled,
  StarFilled,
} from '@ant-design/icons';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { useLazyGetAgencyPropertiesQuery } from 'services/properties/propertyApiService';

import s from './Agency.module.scss';

const Agency: FC = () => {
  const { agencyId } = useParams();
  const [getAgencyProperties, { data: agencyProperties }] = useLazyGetAgencyPropertiesQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          pageNumber: 1,
          pageSize: 100,
          id: agencyId ?? '',
        };
        const res = await getAgencyProperties(payload);

        if ('data' in res) {
          console.log('Fetched');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [getAgencyProperties, agencyId]);

  return (
    <div className={s.wrapper}>
      <div className={s.agencyWrapper}>
        <div className={s.topPart}>
          <img
            src={
              agencyProperties?.agency?.profilePicture
                ? agencyProperties?.agency?.profilePicture
                : '/assets/images/avatar/avatar.svg'
            }
            width={50}
            height={50}
            style={{ borderRadius: '50%' }}
            alt="Agency"
          />
          <Typography type="h3">{agencyProperties?.agency?.name}</Typography>
          <Typography type="label"> Agent</Typography>
          <div className={s.starWrapper}>
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
          </div>
        </div>
        <div className={s.middlePart}>
          <div className={s.agencyItem}>
            <PhoneFilled style={{ color: '#7F86A4', fontSize: 20 }} />
            <Typography type="h4" weight={700}>
              {agencyProperties?.agency?.mobileNumber}
            </Typography>
          </div>
          <div className={s.agencyItem}>
            <GlobalOutlined style={{ color: '#7F86A4', fontSize: 20 }} />
            <Typography type="h4" weight={700}>
              {agencyProperties?.agency?.websiteURL}
            </Typography>
          </div>
          <div className={s.agencyItem}>
            <MailOutlined style={{ color: '#7F86A4', fontSize: 20 }} />
            <Typography type="h4" weight={700}>
              {agencyProperties?.agency?.email}
            </Typography>
          </div>
          <div className={s.agencyItem}>
            <FacebookFilled style={{ color: '#7F86A4', fontSize: 20 }} />
            <Typography type="h4" weight={700}>
              greenagencymalta
            </Typography>
          </div>
        </div>
        <div className={s.bottomPart}>
          <Typography type="h5">ABOUT</Typography>
          <Typography type="label">{agencyProperties?.agency?.description}</Typography>
        </div>
      </div>
      <div className={s.propertyContents}>
        <Typography type="h3">Available Properties</Typography>
        {agencyProperties && (
          <div
            className={classNames(s.components, {
              [s['more-than-4']]: agencyProperties?.properties?.properties.length > 4,
            })}>
            {agencyProperties?.properties?.properties.map((item, index) => (
              <PropertyCard key={index} property={item} editable={false} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Agency;
