import React, { FC, useState } from 'react';

import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getEditPropertyRoute, getPropertyDetailRoute } from 'routes/property/list';
import { handleCutString } from 'tools/cutstring';
import { Icon, Label, Typography, message } from 'ui';

import { termsSelector } from 'selectors/propertySlice.selector';
import {
  useDeletePropertyMutation,
  useGetPropertiesMutation,
  useUnWatchPropertyMutation,
  useWatchPropertyMutation,
} from 'services/properties/propertyApiService';
import { removePropertyById, setInitialView, setPropertyList } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import ShareModal from 'pages/propertyDetail/shareModal';

import { PostingTypeEnum } from 'types/property/propertyEnum';
import { GetPropertyType } from 'types/property/propertyTypes';

// Import Mapbox CSS
import s from './PropertyCard.module.scss';

const { confirm } = Modal;

interface PropertyCardProps {
  property: GetPropertyType;
  editable?: boolean;
}

const formatCurrency = (value) => {
  return `€${value.toLocaleString()}`;
};

const PropertyCard: FC<PropertyCardProps> = ({ property, editable }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const terms = useAppSelector(termsSelector);
  const [getProperties] = useGetPropertiesMutation();
  const [deleteProperty, { isLoading: removing }] = useDeletePropertyMutation();
  const [watchProperty, { isLoading: watching }] = useWatchPropertyMutation();
  const [unWatchProperty, { isLoading: unWatching }] = useUnWatchPropertyMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [watched, setWatched] = useState<boolean>(property?.watched);
  const googleMapsUrl = `https://www.google.com/maps?q=${property?.coordinates?.latitude},${property?.coordinates?.longitude}`;

  const showExportConfirm = async (id: string) => {
    confirm({
      title: 'Would you like to remove this property?',
      icon: <InfoCircleOutlined />,
      content: '',
      centered: true,
      mask: true,
      async onOk() {
        const res = await deleteProperty(id);
        if ('data' in res) {
          dispatch(removePropertyById(id));
          message.warning('Property removed!');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDeleteProperty = (id: string) => {
    showExportConfirm(id);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleWatchProperty = async (id: string) => {
    const res = await watchProperty(id!);

    if ('data' in res) {
      message.success('Added to favourites');
      setWatched(true);
      if (terms) {
        const criteria = {
          pageNumber: 1,
          pageSize: 100,
          filters: {
            numberOfBedrooms: terms.bedroomCount === -1 ? undefined : terms.bedroomCount,
            town: terms.searchName,
            priceFrom: terms.moreFilters?.from,
            priceTo: terms.moreFilters?.to,
            propertyTypes: terms.apartOption?.includes('all') ? undefined : terms.apartOption,
            postingType:
              terms.rentOption === 'rent_sale' ? undefined : (terms.rentOption as PostingTypeEnum),
          },
        };
        const finalRes = await getProperties(criteria);
        if ('data' in finalRes) {
          dispatch(setPropertyList(finalRes.data.properties as GetPropertyType[]));
        }
      }
    }
  };

  const handleUnWatchProperty = async (id: string) => {
    const res = await unWatchProperty(id!);
    if ('data' in res) {
      message.warning('Removed from favourites');
      setWatched(false);
      if (terms) {
        const criteria = {
          pageNumber: 1,
          pageSize: 100,
          filters: {
            numberOfBedrooms: terms.bedroomCount === -1 ? undefined : terms.bedroomCount,
            town: terms.searchName,
            priceFrom: terms.moreFilters?.from,
            priceTo: terms.moreFilters?.to,
            propertyTypes: terms.apartOption?.includes('all') ? undefined : terms.apartOption,
            postingType:
              terms.rentOption === 'rent_sale' ? undefined : (terms.rentOption as PostingTypeEnum),
          },
        };
        const finalRes = await getProperties(criteria);
        if ('data' in finalRes) {
          dispatch(setPropertyList(finalRes.data.properties as GetPropertyType[]));
        }
      }
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.propertyLink}>
        <div style={{ position: 'relative', textAlign: 'center' }}>
          {property?.imageURL ? (
            <div
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                if (property?.coordinates.latitude === 0 || property?.coordinates.longitude === 0) {
                  dispatch(setInitialView(undefined));
                } else {
                  dispatch(
                    setInitialView({
                      latitude: property?.coordinates.latitude,
                      longitude: property?.coordinates.longitude,
                      zoom: 14,
                    }),
                  );
                }
                navigate(`${getPropertyDetailRoute(property?.id)}`);
              }}>
              <img
                src={property?.imageURL || '/assets/images/no-image.svg'}
                alt="property"
                className={s.propertyImage}
              />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                if (property?.coordinates.latitude === 0 || property?.coordinates.longitude === 0) {
                  dispatch(setInitialView(undefined));
                } else {
                  dispatch(
                    setInitialView({
                      latitude: property?.coordinates.latitude,
                      longitude: property?.coordinates.longitude,
                      zoom: 14,
                    }),
                  );
                }
                navigate(`${getPropertyDetailRoute(property?.id)}`);
              }}>
              <img src="/assets/images/no-image.svg" alt="property" className={s.propertyImage} />
            </div>
          )}
          {editable && (
            <>
              <Button
                className={s.editButton}
                onClick={() => navigate(`${getEditPropertyRoute(property?.id)}`)}>
                <Icon name="edit" />
              </Button>
              <Button
                className={s.deleteButton}
                disabled={removing}
                type="link"
                onClick={() => handleDeleteProperty(property?.id)}>
                <Icon name="trashBox" size={14} />
              </Button>{' '}
            </>
          )}

          {!watched ? (
            <Button
              disabled={watching}
              className={s.heartButton}
              type="link"
              onClick={() => handleWatchProperty(property?.id)}>
              <Icon name="heart" size={14} />
            </Button>
          ) : (
            <Button
              disabled={unWatching}
              className={s.heartButton}
              type="link"
              onClick={() => handleUnWatchProperty(property?.id)}>
              <Icon name="heartFilled" size={14} />
            </Button>
          )}
          <Button type="link" className={s.shareButton} onClick={showModal}>
            <UploadOutlined style={{ fontSize: 16 }} />
          </Button>
        </div>
        {/* <Button onClick={() => navigate(`${getPropertyDetailRoute(property?.id)}`)}>Preview</Button> */}
        <Typography className={s.price} type="h4" weight={800}>
          {formatCurrency(property?.price)}
        </Typography>
        <Typography type="h5" className={s.PropertyName}>
          {handleCutString(property?.name || '', 20, true)}
        </Typography>
        <div className={s.cardFooter}>
          {property.featuredParameters && property.featuredParameters.length > 0 ? (
            <Label className={s.Label}>
              {property.featuredParameters
                .map((param) => `${param.value} ${param.shortenedCaption}`)
                .join(' • ')}{' '}
              • {property.totalSize}м²
            </Label>
          ) : (
            <div></div>
          )}
          <a
            href={googleMapsUrl}
            className={s.googleLink}
            target="_blank"
            rel="noopener noreferrer">
            <Icon name="googleMap" size={14} />
            Google Maps
          </a>
        </div>
      </div>
      <ShareModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        currentProperty={property}
        currentUrl={`https://onthatstreet.com/my-properties/detail/${property?.id}`}
      />
    </div>
  );
};

export default PropertyCard;
