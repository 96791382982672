import { FC } from 'react';

import { Button } from 'antd';
import { Icon, Label, Typography, message } from 'ui';

import { GetInviteListType } from 'services/user-management/userManagement.api.type';
import {
  useLazyGetInviteListsQuery,
  useProcessInviteMutation,
} from 'services/user-management/userManagementApiService';

import OwnerCard from 'pages/propertyDetail/ownerCard';

import { AcceptInvitationEnum, InviteStatusEnum } from 'types/property/propertyEnum';

import s from './InvitationCard.module.scss';

interface InvitationCardProps {
  invite: GetInviteListType;
  isActive?: boolean;
}

const formatCurrency = (value) => {
  return `€${value.toLocaleString()}`;
};

const formatPrice = (value: number): string => {
  if (value >= 1_000_000) {
    return `€${(value / 1_000_000).toFixed(1)} M`;
  } else if (value >= 1_000) {
    return `€${(value / 1_000).toFixed(1)} K`;
  } else {
    return `€${value}`;
  }
};

const InvitationCard: FC<InvitationCardProps> = ({ invite, isActive }) => {
  const [processInvitaion, { isLoading }] = useProcessInviteMutation();
  const [getInviteList] = useLazyGetInviteListsQuery();

  const handleAccept = async (id: string) => {
    try {
      const res = await processInvitaion({ status: AcceptInvitationEnum.Accept, id });

      if ('data' in res) {
        message.success('Accepted invitation');
        const criteria = {
          pageNumber: 1,
          pageSize: 100,
          status: InviteStatusEnum.Pending,
        };
        getInviteList(criteria);
      } else {
        message.error('Failed to accept invitation');
      }
    } catch (error) {
      message.error('An error occurred while accepting the invitation');
      console.error(error);
    }
  };

  const handleDecline = async (id: string) => {
    try {
      const res = await processInvitaion({ status: AcceptInvitationEnum.Reject, id });

      if ('data' in res) {
        message.success('Declined invitation');
        const criteria = {
          pageNumber: 1,
          pageSize: 100,
          status: InviteStatusEnum.Pending,
        };
        getInviteList(criteria);
      } else {
        message.error('Failed to decline invitation');
      }
    } catch (error) {
      message.error('An error occurred while accepting the invitation');
      console.error(error);
    }
  };

  return (
    <div className={`${s.wrapper} ${isActive ? s.active : ''}`}>
      <Typography type="h5">You were invited to manage this property by owner</Typography>
      <div className={s.property}>
        <div className={`${s.propertyLink} ${isActive ? s.active : ''}`}>
          <div style={{ position: 'relative', textAlign: 'center' }}>
            {invite?.imageURL ? (
              <div
                style={{ cursor: 'pointer', border: '1px solid #CED2E5', borderRadius: 8 }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}>
                <img src={invite?.imageURL} alt="property" className={s.propertyImage} />
              </div>
            ) : (
              <div
                style={{ cursor: 'pointer', border: '1px solid #CED2E5', borderRadius: 8 }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}>
                <img src="/assets/images/no-image.svg" alt="property" className={s.propertyImage} />
              </div>
            )}
            <Button className={s.imageCount} type="link">
              <Icon name="camera" size={14} />
              {invite?.imageCount}
            </Button>
          </div>
          <div className={s.info}>
            <Typography className={s.price} weight={700}>
              {formatCurrency(invite?.price)}
            </Typography>
            <Typography className={s.address}> {invite?.name}</Typography>
            <Label>
              {invite.featuredParameters
                .map((param) => `${param.value} ${param.shortenedCaption}`)
                .join(' • ')}{' '}
              • {invite.totalSize}м²
            </Label>
          </div>
          <div className={s.mobileInfo}>
            <Typography type="h6" weight={700}>
              {formatPrice(invite?.price)}
            </Typography>
          </div>
        </div>
      </div>
      <div className={s.owner}>
        <div style={{ minWidth: 420 }}>
          <OwnerCard
            firstName={invite?.owner?.firstName}
            lastName={invite?.owner?.lastName}
            mobileNumber={invite?.owner?.mobileNumber}
            profileImageURL={invite?.owner?.profileImageURL}
            hideMobile={invite?.owner?.contactDetailsPrivate && !invite?.isEditable}
          />
        </div>
        <Button
          type="primary"
          disabled={isLoading}
          className={s.acceptButton}
          onClick={() => handleAccept(invite?.inviteId)}>
          ACCEPT INVITATION
        </Button>
        <Button
          type="primary"
          disabled={isLoading}
          className={s.declineButton}
          onClick={() => handleDecline(invite?.inviteId)}>
          DECLINE INVITATION
        </Button>
      </div>
    </div>
  );
};
export default InvitationCard;
