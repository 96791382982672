import { FC } from 'react';

import { Empty } from 'antd';
import { Typography } from 'ui';

import s from './Messages.module.scss';

const Messages: FC = () => {
  return (
    <div>
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: 260 }}
        description={<Typography type="h3">No messages</Typography>}
        className={s.empty}
      />
    </div>
  );
};
export default Messages;
