export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';

export const INVITE_REGISTER_ROUTE = '/register/:inviteId';

export const FORGOT_PASSWORD_ROUTE = '/reset-password';

export const REDIRECT_ROUTE = '/redirect';

export const USER_SETTING_ROUTE = '/organiser-details';

export const USER_SETTING_BANK_DETAILS_ROUTE = '/bank-details';

export const LANDING_ROUTE = '/';

export const PROFILE_ROUTE = '/profile';

export const AGENCY_ROUTE = `/agency/:agencyId`;

export const NOTIFICATION_ROUTE = '/profile?step=notifications';

export const getAgencyRoute = (agencyId: string) => AGENCY_ROUTE.replace(':agencyId', agencyId);

export const getInviteRegisterRoute = (inviteId: string) =>
  INVITE_REGISTER_ROUTE.replace(':inviteId', inviteId);
