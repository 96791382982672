import { Dispatch, FC, SetStateAction } from 'react';

import { Button, Modal } from 'antd';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Icon, message } from 'ui';

import { GetPropertyType } from 'types/property/propertyTypes';

import { formatCurrency } from '../propertyDetail.util';
import s from './ShareModal.module.scss';

interface ShareModalProps {
  isModalOpen?: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  currentProperty?: GetPropertyType;
  currentUrl?: string;
}

const ShareModal: FC<ShareModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  currentProperty,
  currentUrl,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };

  console.log(currentUrl);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(currentUrl!);
      message.success('Copied to clipboard');
    } catch (error) {
      message.error('Failed to copy.');
    }
  };
  return (
    <Modal
      width={600}
      centered
      title={<div style={{ fontSize: 24, fontWeight: 700 }}>Share this page</div>}
      open={isModalOpen}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}>
      <div className={s.modalWrapper}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <img
            src={currentProperty?.imageURL || '/assets/images/no-image.svg'}
            alt="Share info"
            width={64}
            height={64}
            style={{ borderRadius: 6 }}
          />
          <span className={s.modalComment}>
            <strong>{currentProperty?.name}</strong>
            <br /> {formatCurrency(currentProperty?.price)}{' '}
            {currentProperty?.featuredParameters
              .map((param) => `${param.value} ${param.shortenedCaption}`)
              .join(' • ')}{' '}
            • {currentProperty?.totalSize}м² {currentProperty?.address?.street}{' '}
          </span>
        </div>
        <div className={s.shareButtonGroup}>
          <FacebookShareButton
            url={currentUrl!}
            hashtag="#reactshare"
            className={s.netWorkShareButton}>
            <img src="/assets/images/facebook.png" alt="facebook" width={32} height={32} />
            Facebook
          </FacebookShareButton>
          <FacebookMessengerShareButton
            className={s.netWorkShareButton}
            url={currentUrl!}
            appId="46aaef92373e98a5b29110c87176f1b6">
            <img src="/assets/images/messenger.png" alt="messenger" width={32} height={32} />
            Messenger
          </FacebookMessengerShareButton>

          <WhatsappShareButton url={currentUrl!} className={s.netWorkShareButton}>
            <img src="/assets/images/whatsapp.png" alt="whatsapp" width={32} height={32} />
            WhatsApp
          </WhatsappShareButton>

          <Button className={s.shareButton} onClick={handleCopy}>
            <div className={s.shareIconWrapper}>
              <Icon name="clone" size={24} />
            </div>
            Copy Link
          </Button>
          {/* <Button className={s.shareButton}>
            <div className={s.shareIconWrapper}>
              <Icon name="mail" size={24} />
            </div>
            E-mail
          </Button>
          <Button className={s.shareButton}>
            <div className={s.shareIconWrapper}>
              <Icon name="doc" size={24} />
            </div>
            Embed Code
          </Button> */}
        </div>
      </div>
    </Modal>
  );
};
export default ShareModal;
